import React from 'react'
import '../index.css'
const ServeSection = () => {
  return (
    <div>
      <section class="serve">
      <div class="container">
        <h2 class="h2">Who do we serve?</h2>
        <p class="text">
          Every buyer and seller of tech and services in the world.
        </p>
      </div>
      <div class="container serve-inner">
        <div class="serve-inner-block orange">
          <img
            src="icons/serve/buyers.svg"
            alt=""
            class="serve-inner-block-img"
          />
          <h2 class="h2">Buyers</h2>
          <p class="text">
            Torquem detraxit hosti et quidem se texit, ne ad eam.
          </p>
          <a href="#" class="btn inverse">Get started</a>
        </div>
        <div class="serve-inner-block">
          <img
            src="icons/serve/seller.svg"
            alt=""
            class="serve-inner-block-img"
          />
          <h2 class="h2">Seller</h2>
          <p class="text">
            Torquem detraxit hosti et quidem se texit, ne ad eam.
          </p>
          <a href="#" class="btn">Get started</a>
        </div>
      </div>
    </section>
      
    </div>
  )
}

export default ServeSection
