import React from 'react'
import Logo from "../Assets/NewLogo.png"
import "../index.css"
const Loading = () => {
    return (
        
            <div class="loader">
                <img src={Logo} alt="Logo de pantalla de carga" class="loader__img"/>
                <h2 class="loader__text">BoT-MasT</h2>
            </div>
    )
}

export default Loading
