import React from 'react'
import '../index.css'
import Campaign_img from "../Assets/Campaign - 1Campaign Page.svg"
const IntroSection = () => {
  const handleButtonClick = () => {
    window.location.href = 'https://calendly.com/soubhav101101/30min?month=2024-05';
  };
  return (
    <div>
      <section class="intro-sect">
      <div class="container">
        <div class="intro-sect-inner">
          <div class="intro-sect-text-block">
            <h1 class="h1">
              Ultimate Real Estate CRM & Marketing Automation Powerhouse
            </h1>
            <p class="text">
            Revolutionize your real estate game with our powerful CRM !
            
            </p>
            <p class="text">
             Effortlessly manage leads, employees, and inventory, all in one user-friendly platform. Transform efficiency into your competitive edge.
            </p>
            {/* <form class="intro-subsribe-form" action="">
              <input
                class="intro-subscribe-input"
                type="email"
                placeholder="Your email address"
              /> */}
              <button class="btn" onClick={handleButtonClick}>Book Demo</button>
            {/* </form> */}
          </div>
          <div class="intro-sect-img">
            <img class="intro-pic" src={Campaign_img} alt="" />
          </div>
        </div>
      </div>
    </section>
      
    </div>
  )
}

export default IntroSection
