import React from 'react'
import Startup from '../Assets/startup.png'
import '../index.css'
const ContactSection = () => {
  return (
    <div>
      <section class="contact">
      <div class="contact-background">
        <div class="container contact-inner">
          <div class="contact-block-left">
            <h2 class="h2">Get experience from the expert</h2>
            <p class="text">
              Quid ex eo ortum, tam inportuno tamque crudeli; sin, ut aliquid ex
            </p>
            <a href="#" class="btn inverse">Contact us</a>
          </div>
          <div class="contact-block-right">
            <div class="contact-circle"></div>
            <img src={Startup} alt="" class="contact-woman-photo" />
          </div>
        </div>
      </div>
    </section>
      
    </div>
  )
}

export default ContactSection
