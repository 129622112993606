import React from 'react'
import '../index.css'
import Facebook from '../Assets/facebook.png'
import Youtube from '../Assets/youtube.png'
import Twitter from '../Assets/twitter.png'
import Linkedin from '../Assets/social.png'
const FooterSection = () => {
  return (
    <div>
      <footer class="footer">
      <div class="container flex-container">
        <div class="footer-col1">
          <a href="#"><img class="logo" src="img/logo.svg" alt="" /></a>
          <p class="text">
            Quae fuerit causa, nollem me ab illo inventore veritatis et.
          </p>
          <div class="social-icons">
            <div class="social-icon-item facebook">
              <div class="tooltip">Facebook</div>
              <a href="" class="social-link"
                ><img
                  src={Facebook}
                  alt=""
                  class="social-link-img"
              /></a>
            </div>
            <div class="social-icon-item twitter">
              <div class="tooltip">Twitter</div>
              <a href="" class="social-link"
                ><img
                  src={Twitter}
                  alt=""
                  class="social-link-img"
              /></a>
            </div>
            <div class="social-icon-item youtube">
              <div class="tooltip">Youtube</div>
              <a href="" class="social-link"
                ><img
                  src={Youtube}
                  alt=""
                  class="social-link-img"
              /></a>
            </div>
            <div class="social-icon-item linkedin">
              <div class="tooltip">Linkedin</div>
              <a href="" class="social-link"
                ><img
                  src={Linkedin}
                  alt=""
                  class="social-link-img"
              /></a>
            </div>
          </div>
        </div>
        <div class="footer-col-normal">
          <h5 class="h5">Privacy</h5>
          <p class="footer-link"><a href="">Si sine causa</a></p>
          <p class="footer-link"><a href="">Quae fuerit causa</a></p>
          <p class="footer-link"><a href="">Certe, inquam</a></p>
          <p class="footer-link"><a href="">Torquatos nostros? </a></p>
        </div>
        <div class="footer-col-normal">
          <h5 class="h5">Legal</h5>
          <p class="footer-link"><a href="">Ut placet, inquam </a></p>
          <p class="footer-link"><a href="">Certe, inquam</a></p>
          <p class="footer-link"><a href="">Et quidem rerum</a></p>
          <p class="footer-link"><a href="">At vero eos et</a></p>
        </div>
        <div class="footer-col-normal">
          <h5 class="h5">Contact</h5>
          <p class="footer-link"><a href="">Primum igitur</a></p>
          <p class="footer-link"><a href="">Certe, inquam</a></p>
          <p class="footer-link"><a href="">Quae fuerit causa</a></p>
        </div>
      </div>
    </footer>
      
    </div>
  )
}

export default FooterSection
