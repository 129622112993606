import React from 'react'
import '../index.css'
import Logo from '../Assets/NewLogo.png'

const Header = () => {
  return (
    <div>
      <header class="header container">
      <nav class="header-nav">
        <div class="header-nav-logo" >
          <img class="logo" src={Logo} alt=""/> 
          <div className='bot-mast'>Bot-Mast</div>
          
        </div>
        
        
        <ul class="navbar">
          <li class="navbar-item">
            <a class="navbar-link active" href="">Home</a>
          </li>
          <li class="navbar-item">
            <a class="navbar-link" href="">About us</a>
          </li>
          {/* <li class="navbar-item">
            <a class="navbar-link" href="">Find space</a>
          </li>
          <li class="navbar-item">
            <a class="navbar-link" href="">Share space</a>
          </li>
          <li class="navbar-item">
            <a class="navbar-link" href="">Promote space</a>
          </li> */}
        </ul>
      </nav>
    </header>
    </div>
  )
}

export default Header
