import './App.css';
import React , {useState, useEffect} from 'react'

import Header from './Components/Header';
import IntroSection from './Components/IntroSection';
import BestSpace from './Components/BestSpace';
import ChooseSection from './Components/ChooseSection';
import ContactSection from './Components/ContactSection';
import FeedbackSection from './Components/FeedbackSection';
import LogoSection from './Components/LogoSection';
import ServeSection from './Components/ServeSection';
import StartSection from './Components/StartSection';
import FooterSection from './Components/FooterSection';
import Loading from './Components/Loading';
import Accordion from './Components/Accordion';


function App() {


  const [shouldVanish, setShouldVanish] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShouldVanish(true);
    }, 2000);

    // Clear the timeout to prevent memory leaks
    return () => clearTimeout(timeout);
  }, []); 




  return (
    <div className="App">
      {/* <h1>Hello World</h1> */}
      <Header/>
      <IntroSection/>
      {/* <LogoSection/> */}
      <BestSpace/>
      <StartSection/>
      <ServeSection/>
      <ChooseSection/>
      <Accordion/>
      {/* <FeedbackSection/> */}
      <ContactSection/>
      <FooterSection/>
      <div className={shouldVanish ? 'vanish' : ''} id="vanishDiv"><Loading/></div>
      {/* <Loading/> */}
    </div>
    // #27366D
  );
}

export default App;
