import React from 'react'
import '../index.css'
import Performance from '../Assets/like.png'
import Authenticity from '../Assets/authenticity.png'
import Friendly from '../Assets/users1.png'
import Automation from '../Assets/software.png'
const ChooseSection = () => {
  return (
    <div>
      <section class="choose">
        <div class="container">
          <h2 class="h2">Why choose us?</h2>
          <div class="choose-line">
            <div class="choose-item">
              <div class="choose-item-round round1">
                <img src={Performance} alt="" class="choose-item-img " />
              </div>
              <h4 class="h4">Unmatched Performance</h4>
              <div class="choose-item-text">
                Experience lightning-fast, reliable performance, ensuring your tasks are completed efficiently without any downtime.

              </div>
            </div>

            <div class="choose-item">
              <div class="choose-item-round round3">
                <img src={Friendly} alt="" class="choose-item-img" />
              </div>
              <h4 class="h4">User-Friendly Interface</h4>
              <div class="choose-item-text">
                Designed with simplicity in mind, our intuitive interface makes managing leads and tasks a seamless experience
              </div>
            </div>
            <div class="choose-item">
              <div class="choose-item-round round4">
                <img src={Automation} alt="" class="choose-item-img" />
              </div>
              <h4 class="h4"> Advanced Automation</h4>
              <div class="choose-item-text">
                Leverage cutting-edge automation features to streamline operations, save time, and boost productivity effortlessly.
              </div>
            </div>
            <div class="choose-item">
              <div class="choose-item-round round2">
                <img src={Authenticity} alt="" class="choose-item-img" />
              </div>
              <h4 class="h4">Authenticity</h4>

              <div class="choose-item-text">
                Trusted by industry professionals, our CRM provides genuine solutions tailored to real estate brokers' unique needs.
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

export default ChooseSection
