import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
export default function AccordionExpandIcon() {
    return (


        <>
            <h2 class="newh2">Just in case you're curious...</h2>
            <div className='accordion'>
                <div className='accordion1'>
                    <Accordion
                        sx={{
                            display: 'inline',
                            margin: '2rem',
                            borderRadius: '15px',
                      }}
                    >
                    <AccordionSummary
                        expandIcon={<AddIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{

                            '& .MuiTypography-root': {
                                color: "black",
                                fontSize: 20,
                                fontWeight: '600',
                                fontFamily: 'Poppins, sans-serif',

                            },
                        }}
                    >
                        <Typography >What is a real estate CRM?</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{

                            '& .MuiTypography-root': {
                                color: "black",
                                fontSize: 16,
                                fontFamily: 'Poppins, sans-serif',
                                textAlign: 'justify'
                            },
                        }}>
                        <Typography>
                            To strengthen relationships with prospective homeowners, a real estate CRM is crucial. Developers and builders can use it to

                            Gather, organize, and monitor real estate enquiries
                            Simplify a day in the life of a real estate broker.
                            Learn all the details about property sales and pipeline.
                            Communicate automatically
                            Manage specialized advertising initiatives.
                            Making all ecosystem stakeholders more visible is also crucial. Brokers and partners benefit from its

                            Send out correspondence that is standardized.
                            Simple lead detail creation or updating
                            Discover the details of their leads' funnel movement.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                        sx={{
                            display: 'inline',
                            margin: '2rem',
                            borderRadius: '15px',
                      }}
                    >
                    <AccordionSummary
                        expandIcon={<AddIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{

                            '& .MuiTypography-root': {
                                color: "black",
                                fontSize: 20,
                                fontWeight: '600',
                                fontFamily: 'Poppins, sans-serif',

                            },
                        }}
                    >
                        <Typography >Why our CRM is the best option for real estate clients? </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{

                            '& .MuiTypography-root': {
                                color: "black",
                                fontSize: 16,
                                fontFamily: 'Poppins, sans-serif',
                                textAlign: 'justify'
                            },
                        }}>
                        <Typography>
                            In this regard, we excels in

                            efficient and affordable,
                            convenient and user-friendly interface,
                            pre-built connections,
                            personalized robots,
                            managing stock levels,
                            and Management of field agents.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                        sx={{
                            display: 'inline',
                            margin: '2rem',
                            borderRadius: '15px',
                      }}
                    >
                    <AccordionSummary
                        expandIcon={<AddIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{

                            '& .MuiTypography-root': {
                                color: "black",
                                fontSize: 20,
                                fontWeight: '600',
                                fontFamily: 'Poppins, sans-serif',

                            },
                        }}
                    >
                        <Typography >What can you accomplish with a real estate CRM?</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{

                            '& .MuiTypography-root': {
                                color: "black",
                                fontSize: 16,
                                fontFamily: 'Poppins, sans-serif',
                                textAlign: 'justify'
                            },
                        }}>
                        <Typography>
                            More site visits, improved inquiry-to-deal ratios, faster response times to inquiries, and increased real estate agent productivity are all possible with an end-to-end real estate CRM.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className='accordion2'>
            <Accordion
                        sx={{
                            display: 'inline',
                            margin: '2rem',
                            borderRadius: '15px',
                      }}
                    >
                    <AccordionSummary
                        expandIcon={<AddIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{

                            '& .MuiTypography-root': {
                                color: "black",
                                fontSize: 20,
                                fontWeight: '600',
                                fontFamily: 'Poppins, sans-serif',

                            },
                        }}
                    >
                        <Typography >What makes real estate CRM so crucial?</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{

                            '& .MuiTypography-root': {
                                color: "black",
                                fontSize: 16,
                                fontFamily: 'Poppins, sans-serif',
                                textAlign: 'justify'
                            },
                        }}>
                        <Typography>
                            A real estate CRM can Manage queries, partners, and agents effectively.
                            Consistent dialogue with prospective purchasers
                            Buyer engagement was enhanced.
                            Efficient administration of properties and referral partners
                            Campaigns and channels that are closely watched
                            Buyer documents and communications can be centralized on this platform.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                        sx={{
                            display: 'inline',
                            margin: '2rem',
                            borderRadius: '15px',
                      }}
                    >
                    <AccordionSummary
                        expandIcon={<AddIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{

                            '& .MuiTypography-root': {
                                color: "black",
                                fontSize: 20,
                                fontWeight: '600',
                                fontFamily: 'Poppins, sans-serif',

                            },
                        }}
                    >
                        <Typography >What problems can a real estate CRM help with?</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{

                            '& .MuiTypography-root': {
                                color: "black",
                                fontSize: 16,
                                fontFamily: 'Poppins, sans-serif',
                                textAlign: 'justify'
                            },
                        }}>
                        <Typography>
                            Problems with capturing inquiries from multiple sources, missing agent follow-ups, slow responses, "no shows" during site visits, and broken buyer journeys are all things that a real estate CRM can fix. Customer relationship management software allows you to monitor and control the buyer's journey, allowing you to make timely adjustments.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div >
        </>
    );
}
