import React from 'react'
import '../index.css'
import Inventory from "../Assets/Campaign - 13Inventory.jpg"

const StartSection = () => {
  const handleButtonClick = () => {
    window.location.href = 'https://calendly.com/soubhav101101/30min?month=2024-05';
  };
  return (
    <div>
      <section class="start container">
      <div class="blue">
        <div class="start-text-block">
          <h2 class="h2">Want to see our product in action ?</h2>
          {/* <p class="text">
            Quid ex eo ortum, tam inportuno tamque crudeli; sin, ut aliquid ex
          </p> */}
          <a class="btn inverse" onClick={handleButtonClick}>Contact us</a>
          <a href="" class="btn inverse">
            <div class="tringle"></div>
            Watch now</a
          >

          <div class="round-gradient"></div>
          <div class="round-gradient second"></div>
          <div class="round-fill"></div>
        </div>
        <div class="start-left-part">
          {/* <!-- <div class="white-rectangle"> --> */}
          <img src={Inventory} alt="" class="dashboard" />
          {/* <!-- </div> --> */}
        </div>
      </div>
    </section>


      
    </div>
  )
}

export default StartSection
