import React from 'react'
import '../index.css'
import Calendar from "../Assets/Appointment - 7Appointments.jpg"
const BestSpace = () => {
  const handleButtonClick = () => {
    window.location.href = 'https://calendly.com/soubhav101101/30min?month=2024-05';
  };
  return (
    <div>
      <section class="best-space container">
      <div class="best-space-text-block">
        <h2 class="h2">The Ultimate Game-Changer For Brokers</h2>
        <p class="text">
        Supercharge your real estate business with our game-changing CRM! 
        </p>
        <p class="text">
        Effortlessly manage leads and automate tasks, making your daily operations a breeze. Experience unparalleled growth and simplicity like never before!
        </p>
        <button class="btn" onClick={handleButtonClick}>Book Demo</button>

      </div>
      <div class="best-space-parent-for-overlay">
        {/* <img src="img/best-space-right.jpg" alt="" class="best-space-right" /> */}
        {/* <div class="best-space-overlay">
          <div class="left-col">
            <div class="best-space-overlay-round">
              <img
                src="icons/building.svg"
                alt="small building icon"
                class="best-space-overlay-icon"
              />
            </div>
          </div>
          <div class="right-col">
            <div class="best-space-overlay-heading">Twelve West Lake</div>
            <div class="best-space-overlay-text">
              Space for up to 150 people
            </div>
          </div>
        </div> */}
      </div>
      <img src={Calendar} alt="" class="best-space-left" />
    </section>
      
    </div>
  )
}

export default BestSpace
